import React from "react";
import style from "./StarRating.module.scss";
import { FC } from "react";

interface StarSvgProps {
  fill?: string;
}

export const StarSvg: React.FC<StarSvgProps> = ({ fill }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1331_322)">
        <path
          d="M23.5426 9.18517C23.3924 8.72054 22.9803 8.39053 22.4927 8.34659L15.8698 7.74523L13.2509 1.61551C13.0578 1.16628 12.6181 0.875488 12.1294 0.875488C11.6408 0.875488 11.201 1.16628 11.0079 1.61656L8.38908 7.74523L1.76514 8.34659C1.27845 8.39158 0.867381 8.72054 0.716296 9.18517C0.565211 9.64981 0.704741 10.1594 1.07291 10.4807L6.07903 14.8711L4.60284 21.3737C4.49483 21.8518 4.6804 22.346 5.07711 22.6328C5.29034 22.7869 5.53982 22.8653 5.79139 22.8653C6.00831 22.8653 6.22347 22.8068 6.41657 22.6913L12.1294 19.2769L17.8402 22.6913C18.2581 22.9427 18.7849 22.9197 19.1807 22.6328C19.5776 22.3452 19.763 21.8508 19.655 21.3737L18.1788 14.8711L23.1849 10.4816C23.5531 10.1594 23.6937 9.65069 23.5426 9.18517Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1331_322">
          <rect
            width="22.9462"
            height="22.9462"
            fill="white"
            transform="translate(0.65625 0.382446)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

interface StarRatingProps {
  rating: number;
}
const StarRating: FC<StarRatingProps> = ({ rating }) => {
  const maxStars = [1, 2, 3, 4, 5];

  return (
    <div className={style.starRating}>
      {maxStars.map((star) => (
        <span key={star}>
          <StarSvg fill={star > rating ? "#9b9b9b" : "#E9B20D"} />
        </span>
      ))}
    </div>
  );
};

export default StarRating;
