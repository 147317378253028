export const GoogleSvg = () => {
  return (
    <svg
      width="83"
      height="28"
      viewBox="0 0 83 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.2438 18.5343C59.866 18.0665 60.8168 16.6883 60.8168 14.8046C60.8168 13.6496 60.4987 12.741 59.8564 12.0621C57.984 10.0829 54.8034 10.9382 54.0105 13.6342C53.6856 14.739 53.6861 14.8233 54.0252 16.029C54.3289 17.1084 55.2883 18.2267 56.0501 18.3892C56.2762 18.4374 56.609 18.5398 56.7898 18.6167C57.2472 18.8114 57.2918 18.8088 58.2438 18.5343H58.2438ZM55.3011 27.2514C53.5967 26.7011 52.0561 25.4042 51.2487 23.84C51.017 23.3912 51.1338 23.2984 52.6595 22.7192C53.7339 22.3113 54.0777 22.266 54.0777 22.5325C54.0777 22.9193 55.1 23.9584 55.801 24.2841C57.2032 24.9356 59.0473 24.5448 59.9224 23.4108C60.3262 22.8875 60.6859 21.496 60.6202 20.7113L60.5852 20.2943L59.9203 20.7525C58.9743 21.4042 57.4103 21.6909 56.1323 21.4468C54.6034 21.1547 53.6353 20.6514 52.6098 19.6156C51.5269 18.5218 51.0607 17.6356 50.7211 16.0259C50.041 12.8016 52.1112 9.35168 55.3414 8.32666C56.9825 7.80588 59.2835 8.14121 60.2542 9.04262L60.6274 9.38911L60.681 8.92566L60.7346 8.46222H62.214H63.6933L63.7413 14.9607C63.7918 21.7883 63.7167 22.7217 63 24.1738C62.2904 25.6116 60.9144 26.744 59.2244 27.2808C58.1574 27.6197 56.401 27.6065 55.3011 27.2514Z"
        fill="black"
      />
      <path
        d="M30.1238 18.0166C31.3962 17.1451 32.0174 15.2507 31.5685 13.6105C31.1944 12.2436 29.6031 10.9797 28.2562 10.9797C26.0837 10.9797 24.5033 12.5648 24.4948 14.7523C24.488 16.4961 25.5802 18.1044 27.02 18.4709C27.9538 18.7086 29.4288 18.4927 30.1238 18.0166ZM26.8281 21.4402C24.2249 20.9816 22.3363 19.3023 21.4467 16.6551C21.0358 15.4323 21.1727 13.3143 21.7403 12.1148C22.962 9.53257 25.2858 8.05032 28.0981 8.05937C33.4569 8.0766 36.6268 13.4551 34.1089 18.2582C33.3888 19.6317 31.3329 21.0963 29.7108 21.3912C28.2597 21.655 28.0659 21.6583 26.8281 21.4402Z"
        fill="black"
      />
      <path
        d="M73.3062 14.1774C73.9132 13.9105 73.6891 13.9829 73.7958 13.9801C73.9025 13.9772 74.5866 13.686 75.1726 13.4179C75.7585 13.1498 76.3554 12.9305 76.499 12.9305C76.6426 12.9305 75.7973 13.2903 75.7973 13.2195C75.7973 13.1486 77.1673 12.5361 77.6651 12.3688C78.5448 12.0733 78.5621 12.0559 78.2819 11.7499C77.4539 10.8456 75.7801 10.6653 74.666 11.3605C73.7628 11.924 73.0425 12.881 72.9033 13.7022C72.7785 14.439 72.7184 14.4358 73.3062 14.1774ZM75.0342 21.4388C72.4354 20.836 70.7318 19.2139 69.8691 16.5211C69.6353 15.7912 69.6431 13.672 69.8823 12.9421C70.8903 9.86641 73.1807 8.05664 76.0652 8.05664C77.7122 8.05664 79.0726 8.60943 80.2542 9.75884C81.0991 10.5807 82.1842 12.3771 82.1842 12.9539C82.1842 13.327 81.9874 13.4397 79.9652 14.2246C78.339 14.8558 77.5543 15.1737 76.2669 15.723C76.0409 15.8194 75.7081 15.9577 75.5273 16.0302C75.3465 16.1027 74.8657 16.3028 74.4589 16.4748C74.0521 16.6468 73.6157 16.819 73.4891 16.8574C73.094 16.9771 74.4533 18.2319 75.1985 18.4354C76.6632 18.8353 77.9521 18.5602 78.9735 17.6296C79.3584 17.279 79.7488 16.9921 79.8411 16.9921C80.1197 16.9921 82.1783 18.3599 82.1814 18.547C82.187 18.8928 80.7441 20.196 79.7903 20.7065C78.9156 21.1748 76.9101 21.732 76.2669 21.6854C76.1313 21.6756 75.5766 21.5646 75.0342 21.4388H75.0342Z"
        fill="black"
      />
      <path
        d="M8.62961 21.3698C6.6235 21.0529 4.68712 20.0034 2.99362 18.315C1.80488 17.1299 1.02336 15.8775 0.368381 14.1082C-0.0480594 12.9833 -0.0384409 9.2751 0.383871 8.13702C1.71904 4.53889 4.27492 2.12459 7.88995 1.04673C9.03865 0.704236 12.6911 0.752278 13.725 1.12348C15.2926 1.68626 17.2118 2.78216 17.748 3.42069C17.9444 3.65463 17.8442 3.81379 16.9214 4.7341C16.3439 5.31006 15.8322 5.78122 15.7844 5.78111C15.7366 5.781 15.4824 5.59807 15.2195 5.3746C13.8576 4.217 11.2392 3.60476 9.25561 3.98017C6.99678 4.40767 5.12335 5.82151 4.07089 7.89299C3.54415 8.92972 3.20211 8.99003 3.20211 11.1426C3.20211 13.1876 3.56427 13.3993 3.9794 14.275C4.22427 14.7915 4.84619 15.651 5.36145 16.185C7.24173 18.1334 9.99004 18.8999 12.6192 18.2091C14.0943 17.8216 14.7262 17.4882 15.6468 16.6117C16.452 15.8452 16.9766 14.8954 17.1628 13.8672L17.2726 13.2614L13.9373 13.2174L10.602 13.1734V11.6563V10.1393L15.3687 10.1528C17.9904 10.1602 20.1807 10.1666 20.236 10.1671C20.2913 10.1676 20.4162 10.5595 20.5134 11.0381C20.8796 12.8402 20.2077 15.5696 18.9183 17.5181C17.8563 19.1229 16.7195 19.989 14.3003 21.0367C13.2969 21.4712 10.3626 21.6436 8.62961 21.3698V21.3698Z"
        fill="black"
      />
      <path
        d="M43.9361 18.4288C44.7009 18.2395 45.727 17.2873 46.1032 16.4179C46.2539 16.0695 46.4962 15.3991 46.4604 14.7293C46.4093 13.7728 46.2037 13.0929 46.001 12.8502C45.1447 11.8251 44.6963 11.4493 44.0681 11.2302C42.6611 10.7396 41.3517 11.008 40.293 12.0041C38.223 13.9516 39.0171 17.5675 41.713 18.4698C42.4856 18.7284 42.7456 18.7236 43.9361 18.4288ZM41.1002 21.374C39.3548 21.01 37.5316 19.6379 36.7047 18.066C35.327 15.447 35.677 12.4099 37.5991 10.3071C40.3412 7.30711 45.1751 7.30846 47.9685 10.31C50.3717 12.8924 50.1226 17.58 47.4624 19.8308C46.1944 20.9036 44.9601 21.3868 43.2977 21.461C42.4914 21.497 41.5025 21.4579 41.1002 21.374V21.374Z"
        fill="black"
      />
      <path
        d="M65.4189 11.3048V1.38281L67.0215 1.42931L68.6241 1.47581V11.3048V21.1338L67.0215 21.1803L65.4189 21.2268V11.3048Z"
        fill="black"
      />
    </svg>
  );
};
export const ClutchSvg = () => {
  return (
    <svg
      width="86"
      height="25"
      viewBox="0 0 86 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.5137 0.71106H26.4024V24.72H22.5137V0.71106Z" fill="black" />
      <path
        d="M39.9277 17.1111C39.9277 20.8308 36.8843 21.1689 35.8699 21.1689C33.5028 21.1689 33.1646 18.9709 33.1646 17.7874V8.48816H29.2759V17.6183C29.2759 19.8163 29.9522 21.8452 31.3048 23.0288C32.4883 24.2123 34.01 24.7195 35.8699 24.7195C37.2225 24.7195 38.9133 24.3814 39.9277 23.3669V24.7195H43.8165V8.48816H39.9277V17.1111Z"
        fill="black"
      />
      <path
        d="M51.4252 2.5708H47.5364V8.48849H44.6621V12.2082H47.5364V24.7199H51.4252V12.2082H54.2995V8.48849H51.4252V2.5708Z"
        fill="black"
      />
      <path
        d="M66.4733 19.8165C65.6279 20.4928 64.4444 21 63.2608 21C60.5556 21 58.6958 18.9711 58.6958 16.2658C58.6958 13.5606 60.5556 11.7008 63.2608 11.7008C64.4444 11.7008 65.6279 12.0389 66.4733 12.8843L66.9805 13.3915L69.6858 10.8554L69.0095 10.3481C67.4878 8.99553 65.4588 8.15015 63.2608 8.15015C58.5267 8.15015 54.9761 11.7008 54.9761 16.4349C54.9761 21.1691 58.5267 24.7197 63.2608 24.7197C65.4588 24.7197 67.4878 23.8743 69.0095 22.5217L69.6858 22.0145L66.9805 19.3092L66.4733 19.8165Z"
        fill="black"
      />
      <path
        d="M83.3808 9.84122C82.1973 8.65768 81.0137 8.15045 79.1539 8.15045C77.8013 8.15045 76.4486 8.4886 75.4342 9.50306V0.71106H71.5454V24.72H75.4342V15.7589C75.4342 12.0392 77.9703 11.7011 78.9848 11.7011C81.3519 11.7011 81.1828 13.8991 81.1828 15.0826V24.5509H85.0716V15.2517C85.0716 13.0537 84.5643 11.0248 83.3808 9.84122Z"
        fill="black"
      />
      <path
        d="M63.092 19.1409C64.586 19.1409 65.7972 17.9298 65.7972 16.4357C65.7972 14.9416 64.586 13.7305 63.092 13.7305C61.5979 13.7305 60.3867 14.9416 60.3867 16.4357C60.3867 17.9298 61.5979 19.1409 63.092 19.1409Z"
        fill="#EF4335"
      />
      <path
        d="M17.1027 18.8022C15.7501 20.1548 13.7212 21.0002 11.5232 21.0002C7.29628 21.0002 4.2529 17.6187 4.2529 13.0536C4.2529 8.48851 7.29628 5.10697 11.6923 5.10697C13.7212 5.10697 15.7501 5.95235 17.2718 7.47404L17.779 7.98128L20.3152 5.44512L19.808 4.93789C17.61 2.73989 14.7357 1.55635 11.6923 1.55635C5.26736 1.38728 0.533203 6.45958 0.533203 13.0536C0.533203 19.6476 5.26736 24.7199 11.5232 24.7199C14.5666 24.7199 17.61 23.5364 19.6389 21.3384L20.1461 20.8311L17.61 18.295L17.1027 18.8022Z"
        fill="black"
      />
    </svg>
  );
};
