"use client";

import { Icon } from "@/components/common/Icons/Icon";
import { MagneticCursor } from "@/components/common/MagneticCursor/MagneticCursor";
import { useContact } from "@/provider/ContextForm";
import { motion } from "framer-motion";
import { useLocale, useTranslations } from "next-intl";
import { useState } from "react";
import LinkTransition from "../../../../../transition/components/LinkTransition/LinkTransition";
import {
  contactInfo,
  navPage,
  navTemplate,
  socialTuxdi,
} from "../../footerData";
import { FooterCards } from "./FooterCards/FooterCards";
import style from "./FooterContactDetails.module.scss";

const MaskText = ({ text, isHovered }) => {
  const locale = useLocale();
  const animation = {
    initial: { y: "100%" },
    enter: {
      y: "0",
      transition: {
        duration: 0.5,
        ease: [0.33, 1, 0.68, 1],
      },
    },
  };

  return (
    <div className={style.body}>
      <div className={`${locale === "en" ? style.lineMaskEn : style.lineMask}`}>
        <motion.p
          variants={animation}
          animate={isHovered ? "enter" : "initial"}
          initial="initial"
        >
          {text}
        </motion.p>
      </div>
    </div>
  );
};

export const FooterContactDetails = () => {
  const translate = useTranslations("footerContact");
  const translateNav = useTranslations("nav");

  const [hoveredItem, setHoveredItem] = useState(false);

  const { setIsContactOpen } = useContact();

  const handleHover = () => {
    setHoveredItem(true);
  };

  const handleUnhover = () => {
    setHoveredItem(false);
  };
  const handleClick = () => {
    setIsContactOpen(true);
  };
  const navPageTranslate = navPage.map((item) => ({
    ...item,
    title: translateNav(item.key),
  }));
  return (
    <>
      <div className={style.infoCards}>
        <div className={style.firstRow}>
          <div className={style.title} onClick={handleClick}>
            <h1
              data-hover="footer-title"
              onMouseEnter={handleHover}
              onMouseLeave={handleUnhover}
            >
              {translate("title")}
            </h1>
            <MaskText text={translate("title_mask")} isHovered={hoveredItem} />
          </div>
          <div className={style.linksContainer}>
            <div className={style.linksNavigation}>
              <h2>{translate("about_tuxdi")}</h2>
              <ul className={style.listLinks}>
                {navPageTranslate.map((item, i) => (
                  <li key={i}>
                    <LinkTransition
                      href={item.href as any}
                      className={style.linkLabel}
                      aria-label={item.ariaLabel}
                    >
                      {item.title}
                    </LinkTransition>
                  </li>
                ))}
              </ul>
            </div>
            <div className={style.linksNavigation}>
              <h2>{translate("areas_expertise")}</h2>
              <ul className={style.listLinks}>
                {navTemplate.map((item, i) => (
                  <li key={i}>
                    <LinkTransition
                      href={("/servicios" + item.href) as any}
                      className={style.linkLabel}
                      aria-label={item.ariaLabel}
                    >
                      {item.key}
                    </LinkTransition>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={style.secondRow}>
          <div className={style.infoSocial}>
            <div className={style.moreInfo}>
              <div className={style.contactInfo}>
                <span>{contactInfo.subtitle}</span>
                <span>
                  <a
                    href={`tel:${contactInfo.phone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {contactInfo.phone}
                  </a>
                </span>
                <span>
                  <a
                    href={`mailto:${contactInfo.email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {contactInfo.email}
                  </a>
                </span>
              </div>
              <p className={style.location}>
                2201 Menaul Blvd NE STE Albuquerque, NM 87107
              </p>
            </div>
            <ul className={style.socialContainer}>
              {socialTuxdi &&
                socialTuxdi.map((item) => (
                  <MagneticCursor key={item.id}>
                    <li>
                      <a
                        target="_blank"
                        href={item.href}
                        aria-label={item.ariaLabel}
                      >
                        <Icon name={item.icon} />
                      </a>
                    </li>
                  </MagneticCursor>
                ))}
            </ul>
          </div>
          <FooterCards />
        </div>
      </div>
    </>
  );
};
