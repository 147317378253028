export const contactInfo = {
  title: "Trabajemos Juntos",
  titleHover: "Estás a un paso de llevar tu proyecto al éxito",
  subtitle: "Tuxdi LLC",
  phone: "+54 (249) 469 8992",
  email: "info@tuxdi.com",
};

export const navPage = [
  {
    key: "home",
    href: "/",
    ariaLabel: "inicio",
  },
  {
    key: "about",
    href: "/nosotros",
    ariaLabel: "go to nosotros",
  },
  {
    key: "projects",
    href: "/proyectos",
    ariaLabel: "go to proyectos",
  },
  {
    key: "services",
    href: "/servicios",
    ariaLabel: "go to servicios",
  },
  {
    key: "joinUs",
    href: "/trabaja-con-nosotros",
    ariaLabel: "go to join us",
  },
  {
    key: "blog",
    href: "/blog",
    ariaLabel: "go to blog page",
  },
];

export const navTemplate = [
  {
    key: "UX/UI",
    href: "/ux-ui",
    ariaLabel: "go to ux ui template",
  },
  {
    key: "techLab",
    href: "/tech-lab",
    ariaLabel: "go to tech lab template",
  },
  {
    key: "mobile",
    href: "/mobile",
    ariaLabel: "go to mobile template",
  },
  {
    key: "web",
    href: "/web",
    ariaLabel: "go to web template",
  },
];

export const socialTuxdi = [
  {
    id: 1,
    icon: "Clutch",
    href: "https://clutch.co/profile/tuxdi#highlights",
    ariaLabel: "check our clutch",
  },
  {
    id: 2,
    icon: "LinkedIn",
    href: "https://www.linkedin.com/company/tuxdi/",
    ariaLabel: "check our linkedin",
  },
  {
    id: 3,
    icon: "Facebook",
    href: "https://www.facebook.com/tuxdiARG/",
    ariaLabel: "check our facebook",
  },
  {
    id: 4,
    icon: "Instagram",
    href: "https://www.instagram.com/tuxdidigital",
    ariaLabel: "check our instagram",
  },
];

export const termsAndPrivacy = [
  {
    id: 1,
    titleKey: "terms",
    href: "/terminos-y-condiciones",
    ariaLabel: "To terms and conditions",
  },
  {
    id: 2,
    titleKey: "privacy_policies",
    href: "/politicas-de-privacidad",
    ariaLabel: "To privacy policy",
  },
  {
    id: 3,
    titleKey: "policies_quality",
    href: "/politicas-de-calidad",
    ariaLabel: "To quality policy",
  },
];
