"use client";
import style from "./FooterCopyrightLinks.module.scss";
import { termsAndPrivacy as mockTermsAndPrivacy } from "../../footerData";
import { useEffect, useState } from "react";
import { MagneticCursor } from "@/components/common/MagneticCursor/MagneticCursor";
import { Link } from "@/navigation";
import { useTranslations } from "next-intl";

export const CopyRightIcons = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99998 16.25H14C14.2125 16.25 14.3906 16.1781 14.5344 16.0343C14.6781 15.8906 14.75 15.7125 14.75 15.5V13.75H13.25V14.75H10.75V10.25H13.25V11.25H14.75V9.49965C14.75 9.28705 14.6781 9.10896 14.5344 8.96538C14.3906 8.82179 14.2125 8.75 14 8.75H9.99998C9.78748 8.75 9.60936 8.82187 9.46563 8.9656C9.32188 9.10935 9.25 9.28748 9.25 9.49998V15.5C9.25 15.7125 9.32188 15.8906 9.46563 16.0343C9.60936 16.1781 9.78748 16.25 9.99998 16.25ZM12.0016 22C10.6877 22 9.45268 21.7506 8.29655 21.252C7.1404 20.7533 6.13472 20.0765 5.2795 19.2217C4.42427 18.3669 3.74721 17.3616 3.24833 16.206C2.74944 15.0504 2.5 13.8156 2.5 12.5017C2.5 11.1877 2.74933 9.95268 3.248 8.79655C3.74667 7.6404 4.42342 6.63472 5.27825 5.7795C6.1331 4.92427 7.13834 4.24721 8.29398 3.74833C9.44959 3.24944 10.6844 3 11.9983 3C13.3122 3 14.5473 3.24933 15.7034 3.748C16.8596 4.24667 17.8652 4.92342 18.7205 5.77825C19.5757 6.6331 20.2527 7.63834 20.7516 8.79398C21.2505 9.94959 21.5 11.1844 21.5 12.4983C21.5 13.8122 21.2506 15.0473 20.752 16.2034C20.2533 17.3596 19.5765 18.3652 18.7217 19.2205C17.8669 20.0757 16.8616 20.7527 15.706 21.2516C14.5504 21.7505 13.3156 22 12.0016 22ZM12 20.5C14.2333 20.5 16.125 19.725 17.675 18.175C19.225 16.625 20 14.7333 20 12.5C20 10.2666 19.225 8.37498 17.675 6.82498C16.125 5.27498 14.2333 4.49998 12 4.49998C9.76664 4.49998 7.87498 5.27498 6.32498 6.82498C4.77498 8.37498 3.99998 10.2666 3.99998 12.5C3.99998 14.7333 4.77498 16.625 6.32498 18.175C7.87498 19.725 9.76664 20.5 12 20.5Z"
        fill="#17082F"
      />
    </svg>
  );
};
export const ScrollUpIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4699 3.46995C11.6105 3.3295 11.8012 3.25061 11.9999 3.25061C12.1987 3.25061 12.3893 3.3295 12.5299 3.46995L18.5299 9.46995C18.6036 9.53861 18.6627 9.62141 18.7037 9.71341C18.7447 9.80541 18.7667 9.90473 18.7685 10.0054C18.7703 10.1061 18.7518 10.2062 18.714 10.2995C18.6763 10.3929 18.6202 10.4778 18.5489 10.549C18.4777 10.6202 18.3929 10.6764 18.2995 10.7141C18.2061 10.7518 18.1061 10.7703 18.0054 10.7685C17.9047 10.7668 17.8054 10.7447 17.7134 10.7037C17.6214 10.6627 17.5386 10.6036 17.4699 10.53L12.7499 5.80995V20C12.7499 20.1989 12.6709 20.3896 12.5302 20.5303C12.3896 20.6709 12.1988 20.75 11.9999 20.75C11.801 20.75 11.6102 20.6709 11.4696 20.5303C11.3289 20.3896 11.2499 20.1989 11.2499 20V5.80995L6.52991 10.53C6.46125 10.6036 6.37845 10.6627 6.28645 10.7037C6.19445 10.7447 6.09513 10.7668 5.99443 10.7685C5.89373 10.7703 5.7937 10.7518 5.70031 10.7141C5.60692 10.6764 5.52209 10.6202 5.45087 10.549C5.37965 10.4778 5.32351 10.3929 5.28579 10.2995C5.24807 10.2062 5.22954 10.1061 5.23132 10.0054C5.23309 9.90473 5.25514 9.80541 5.29613 9.71341C5.33712 9.62141 5.39622 9.53861 5.46991 9.46995L11.4699 3.46995Z"
        fill="var(--primary)"
      />
    </svg>
  );
};

export const FooterCopyrightLinks = () => {
  const translate = useTranslations("footerContact");
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window) setIsMobile(window.innerWidth < 768);
  }, [isMobile]);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const termsAndPrivacy = mockTermsAndPrivacy.map((item) => ({
    ...item,
    title: translate(item.titleKey),
  }));

  return (
    <div className={style.lastFooterContainer}>
      <div className={style.termsPrivacy}>
        {termsAndPrivacy &&
          termsAndPrivacy.map((item) => (
            <Link key={item.id} href={item.href as any} role="link">
              {item.title}
            </Link>
          ))}
      </div>

      <div className={style.copyRightItems}>
        <CopyRightIcons />
        <span className={style.spanAfter}>2024 Tuxdi LLC</span>
        <span>Digital Agency</span>
      </div>

      <div className={style.arrowUpContainer}>
        {!isMobile ? (
          <MagneticCursor>
            <div className={style.svgContainer} onClick={handleClick}>
              <ScrollUpIcon />
            </div>
          </MagneticCursor>
        ) : (
          <div className={style.svgContainer} onClick={handleClick}>
            <ScrollUpIcon />
          </div>
        )}
      </div>
    </div>
  );
};
