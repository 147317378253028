import { ClutchSvg, GoogleSvg } from "./svgIcons";
import style from "./FooterCards.module.scss";
import StarRating from "./StarRating/StarRating";

export const FooterCards = () => {
  return (
    <div className={style.cardsCotaniner}>
      <a
        href="https://g.page/r/CbC_FlFTP4RjEB0/review"
        target="_blank"
        className={style.cardDetail}
      >
        <div className={style.textSvg}>
          <span>Reviewed on</span>
          <GoogleSvg />
        </div>
        <StarRating rating={5} />
      </a>
      <a
        href="https://clutch.co/profile/tuxdi#highlights"
        target="_blank"
        className={style.cardDetail}
      >
        <div className={style.textSvg}>
          <span>Reviewed on</span>
          <ClutchSvg />
        </div>
        <StarRating rating={5} />
      </a>
    </div>
  );
};
